
import { auth, firestore } from "@/firebase";
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";

import * as UserServices from '@/services/UserServices';

@Component({
  components: {
    NewAccountDialog: () => import('@/components/dialogs/NewAccount.vue')
  }
})
export default class AccountsOverview extends Vue {
  users = [] as any[];

  dialogs = {
    newAccount: false,
  }

  loading = {
    users: false,
  }

  mounted(){
    this.fetchUsers();
  }

  async fetchUsers(){
    try {
      this.loading.users = true;
      this.users = await UserServices.ListUsers();
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading.users = false;
    }
  }
}
